// https://vuex.vuejs.org/en/getters.html
import { getUnixTime } from 'date-fns';

export default {
    isAuthenticated(state) {
        return (
            state.userId !== null &&
            state.userId !== undefined &&
            state.subscription !== null &&
            state.subscription !== undefined
        );
    },
    isSuperUser(state) {
        return state.userDetails.permissions == 0xa5 || state.userDetails.permissions == 0xaa;
    },
    isOwner(state) {
        return state.userDetails.permissions == 0xaa;
    },
    isPayrollUnlocked(state) {
        return state.userDetails.permissions == 0xaa || (state.userDetails.permissionsMap & 0x01) == 0x01;
    },
    isPurchaseOrderAdmin(state) {
        return state.userDetails.permissions == 0xaa || (state.userDetails.permissionsMap & 0x040000) == 0x040000;
    },
    isUserAddDeleteUnlocked(state) {
        return state.userDetails.permissions == 0xaa || (state.userDetails.permissionsMap & 0x02) == 0x02;
    },
    hasContractPermissions(state) {
        return state.userDetails.permissions == 0xaa || state.userDetails.permissionsMap & 0x08;
    },
    hasPaymentPermissions(state) {
        return state.userDetails.permissions == 0xaa || 
            ((state.userDetails.permissionsMap & 0x08) && state.userDetails.viewPermissionsMap & 0x02);
    },
    areBasicFeaturesUnlocked(state) {
        return (
            state.subscription.betaTester == true ||
            ((state.subscription.productId == 'com.croppaco.farmSimple.BasicMonthly' ||
                state.subscription.productId == 'com.croppaco.farmSimple.CroppingMonthly' ||
                state.subscription.productId == 'com.croppaco.farmSimple.StandardSub' ||
                state.subscription.productId == 'com.croppaco.farmSimple.EnterpriseSub' ||
                state.subscription.productId == 'com.croppaco.farmSimple.StandardAndHrSub' ||
                state.subscription.productId == 'com.croppaco.farmSimple.EnterpriseAndHrSub') &&
                state.subscription.expiry > getUnixTime(Date()))
        );
    },
    areCroppingFeaturesUnlocked(state) {
        return (
            state.subscription.betaTester == true ||
            ((state.subscription.productId == 'com.croppaco.farmSimple.CroppingMonthly' ||
                state.subscription.productId == 'com.croppaco.farmSimple.StandardSub' ||
                state.subscription.productId == 'com.croppaco.farmSimple.EnterpriseSub' ||
                state.subscription.productId == 'com.croppaco.farmSimple.StandardAndHrSub' ||
                state.subscription.productId == 'com.croppaco.farmSimple.EnterpriseAndHrSub') &&
                state.subscription.expiry > getUnixTime(Date()))
        );
    },
    areStandardFeaturesUnlocked(state) {
        return (
            state.subscription.betaTester == true ||
            ((state.subscription.productId == 'com.croppaco.farmSimple.StandardSub' ||
                state.subscription.productId == 'com.croppaco.farmSimple.EnterpriseSub' ||
                state.subscription.productId == 'com.croppaco.farmSimple.StandardAndHrSub' ||
                state.subscription.productId == 'com.croppaco.farmSimple.EnterpriseAndHrSub') &&
                state.subscription.expiry > getUnixTime(Date()))
        );
    },
    areEnterpriseFeaturesUnlocked(state) {
        return (
            state.subscription.betaTester == true ||
            ((state.subscription.productId == 'com.croppaco.farmSimple.EnterpriseSub' ||
                state.subscription.productId == 'com.croppaco.farmSimple.EnterpriseAndHrSub') &&
                state.subscription.expiry > getUnixTime(Date()))
        );
    },
    areHrFeaturesUnlocked(state) {
        return (
            (state.subscription.safetyAccess == true ||
                state.subscription.productId == 'com.croppaco.farmSimple.StandardAndHrSub' ||
                state.subscription.productId == 'com.croppaco.farmSimple.EnterpriseAndHrSub') &&
                state.subscription.expiry > getUnixTime(Date())
        );
    },
    isBoolah(state) {
        return (
            state.subscriberId == '-L_99IIUMU1icw0vLMWs' ||
            state.subscriberId == '-NCTdI-FVQ557NTLGsH6' ||
            state.subscriberId == '-NJwsPkLUYHSU9ofFgdY' ||
            state.subscriberId == '-L7NpADorOGvxKOwVn9k'
        );
    },
    chargeOutAvailable(state) {
        return (
            state.subscriberId == '-L_99IIUMU1icw0vLMWs' ||
            state.subscriberId == '-NCTdI-FVQ557NTLGsH6' ||
            state.subscriberId == '-NJwsPkLUYHSU9ofFgdY' ||
            state.subscriberId == '-L7NpADorOGvxKOwVn9k' ||
            state.subscriberId == '-KEZ8G5vgoJ9noyKFe86' ||
            state.subscriberId == '-LweC-_ujYG4eOvQCEd9'
        );
    },
    getPaddockNomenclature(state) {
        if (state.general && state.general.paddockNomenclature) {
            if (state.general.paddockNomenclature == 1) {
                return "Field";
            }
        }
        return "Paddock";
    }
};
