// https://vuex.vuejs.org/en/mutations.html

export default {
    setUserId(state, payload) {
        state.userId = payload;
    },
    setSubscriberId(state, payload) {
        state.subscriberId = payload;
    },
    setSubscription(state, payload) {
        state.subscription = payload;
    },
    setNewSubFlag(state, payload) {
        state.isNewSub = payload;
    },
    setUsersSubs(state, payload) {
        state.usersSubs = payload;
    },
    setGeneral(state, payload) {
        state.general = payload;
    },
    setGrainSettings(state, payload) {
        state.grainSettings = payload;
    },
    setTimesheetSettings(state, payload) {
        state.timesheetSettings = payload;
    },
    setUserDetails(state, payload) {
        state.userDetails = payload;
    },
    setFirebaseWorking(state, payload) {
        state.firebaseWorking = payload;
    },
    setLoginBusy(state, payload) {
        state.loginBusy = payload;
    },
    setActivePage(state, payload) {
        state.activePage = payload;
    },
    /* Jobs */
    setJobs(state, payload) {
        state.jobs = payload;
    },
    addJob(state, item) {
        state.jobs.push(item);
    },
    updateJob(state, payload) {
        state.jobs.splice(payload.index, 1, payload.item);
    },
    removeJob(state, index) {
        state.jobs.splice(index, 1);
    },
    /* Pump Readings */
    setPumpReadings(state, payload) {
        state.pumpReadings = payload;
    },
    addPumpReading(state, item) {
        state.pumpReadings.push(item);
    },
    updatePumpReading(state, payload) {
        state.pumpReadings.splice(payload.index, 1, payload.item);
    },
    removePumpReading(state, index) {
        state.pumpReadings.splice(index, 1);
    },
    /* Equipment Inspections */
    setEquipmentInspections(state, payload) {
        state.equipmentInspections = payload;
    },
    /* Purchase Orders */
    setPurchaseOrders(state, payload) {
        state.purchaseOrders = payload;
    },
    updatePurchaseOrder(state, payload) {
        state.purchaseOrders.splice(payload.index, 1, payload.item);
    },
    removePurchaseOrder(state, index) {
        state.purchaseOrders.splice(index, 1);
    },
    /* Spray Logs */
    setSprayLogs(state, payload) {
        state.sprayLogs = payload;
    },
    addSprayLog(state, item) {
        state.sprayLogs.push(item);
    },
    updateSprayLog(state, payload) {
        state.sprayLogs.splice(payload.index, 1, payload.item);
    },
    removeSprayLog(state, index) {
        state.sprayLogs.splice(index, 1);
    },
    /* Plantings */
    setPlantings(state, payload) {
        state.plantings = payload;
    },
    addPlanting(state, item) {
        state.plantings.push(item);
    },
    updatePlanting(state, payload) {
        state.plantings.splice(payload.index, 1, payload.item);
    },
    removePlanting(state, index) {
        state.plantings.splice(index, 1);
    },
    /* Spreadings */
    setSpreadings(state, payload) {
        state.spreadings = payload;
    },
    addSpreading(state, item) {
        state.spreadings.push(item);
    },
    updateSpreading(state, payload) {
        state.spreadings.splice(payload.index, 1, payload.item);
    },
    removeSpreading(state, index) {
        state.spreadings.splice(index, 1);
    },
    /* Harvest */
    setHarvests(state, payload) {
        state.harvests = payload;
    },
    addHarvest(state, item) {
        state.harvests.push(item);
    },
    updateHarvest(state, payload) {
        state.harvests.splice(payload.index, 1, payload.item);
    },
    removeHarvest(state, index) {
        state.harvests.splice(index, 1);
    },
    /* Maintenance */
    setMaintenance(state, payload) {
        state.maintenance = payload;
    },
    addMaintenance(state, item) {
        state.maintenance.push(item);
    },
    updateMaintenance(state, payload) {
        state.maintenance.splice(payload.index, 1, payload.item);
    },
    removeMaintenance(state, index) {
        state.maintenance.splice(index, 1);
    },
    /* Irrigation */
    setIrrigations(state, payload) {
        state.irrigations = payload;
    },
    addIrrigation(state, item) {
        state.irrigations.push(item);
    },
    updateIrrigation(state, payload) {
        state.irrigations.splice(payload.index, 1, payload.item);
    },
    removeIrrigation(state, index) {
        state.irrigations.splice(index, 1);
    },
    /* Soil Tests */
    setSoilTests(state, payload) {
        state.soilTests = payload;
    },
    addSoilTest(state, item) {
        state.soilTests.push(item);
    },
    updateSoilTest(state, payload) {
        state.soilTests.splice(payload.index, 1, payload.item);
    },
    removeSoilTest(state, index) {
        state.soilTests.splice(index, 1);
    },
    /* Grazing */
    setGrazings(state, payload) {
        state.grazings = payload;
    },
    addGrazing(state, item) {
        state.grazings.push(item);
    },
    updateGrazing(state, payload) {
        state.grazings.splice(payload.index, 1, payload.item);
    },
    removeGrazing(state, index) {
        state.grazings.splice(index, 1);
    },
    /* Vet Treatments */
    setVetTreatments(state, payload) {
        state.vetTreatments = payload;
    },
    addVetTreatment(state, item) {
        state.vetTreatments.push(item);
    },
    updateVetTreatment(state, payload) {
        state.vetTreatments.splice(payload.index, 1, payload.item);
    },
    removeVetTreatment(state, index) {
        state.vetTreatments.splice(index, 1);
    },
    /* Tickets */
    setTickets(state, payload) {
        state.tickets = payload;
    },
    setFuelTickets(state, payload) {
        state.fuelTickets = payload;
    },
    setChemicalTickets(state, payload) {
        state.chemicalTickets = payload;
    },
    addChemicalTicket(state, item) {
        state.chemicalTickets.push(item);
    },
    setFertiliserTickets(state, payload) {
        state.fertiliserTickets = payload;
    },
    setWaterTickets(state, payload) {
        state.waterTickets = payload;
    },
    setContracts(state, payload) {
        state.contracts = payload;
    },
    /* Timesheets */
    setTimesheets(state, payload) {
        state.timesheets = payload;
    },
    setTimesheetDetails(state, payload) {
        state.timesheetDetails = payload;
    },
    setNewTimesheets(state, payload) {
        state.newTimesheets = payload;
    },
    setTimesheetSubmissions(state, payload) {
        state.timesheetSubmissions = payload;
    },
    setWorkTypes(state, payload) {
        state.workTypes = payload;
    },
    addWorkType(state, item) {
        state.workTypes.push(item);
    },
    updateWorkType(state, payload) {
        state.workTypes.splice(payload.index, 1, payload.item);
    },
    removeWorkType(state, index) {
        state.workTypes.splice(index, 1);
    },
    /* Inductions */
    setInductions(state, payload) {
        state.inductions = payload;
    },
    addInduction(state, item) {
        state.inductions.push(item);
    },
    updateInduction(state, payload) {
        state.inductions.splice(payload.index, 1, payload.item);
    },
    removeInduction(state, index) {
        state.inductions.splice(index, 1);
    },
    /* Qualifications */
    setQualifications(state, payload) {
        state.qualifications = payload;
    },
    addQualification(state, item) {
        state.qualifications.push(item);
    },
    updateQualification(state, payload) {
        state.qualifications.splice(payload.index, 1, payload.item);
    },
    removeQualification(state, index) {
        state.qualifications.splice(index, 1);
    },
    /* Toolbox Meetings */
    setToolboxMeetings(state, payload) {
        state.toolboxMeetings = payload;
    },
    addToolboxMeeting(state, item) {
        state.toolboxMeetings.push(item);
    },
    updateToolboxMeeting(state, payload) {
        state.toolboxMeetings.splice(payload.index, 1, payload.item);
    },
    removeToolboxMeeting(state, index) {
        state.toolboxMeetings.splice(index, 1);
    },
    /* Checklist Templates */
    setChecklistTemplates(state, payload) {
        state.checklistTemplates = payload;
    },
    addChecklistTemplate(state, item) {
        state.checklistTemplates.push(item);
    },
    updateChecklistTemplate(state, payload) {
        state.checklistTemplates.splice(payload.index, 1, payload.item);
    },
    removeChecklistTemplate(state, index) {
        state.checklistTemplates.splice(index, 1);
    },
    /* Indcution Templates */
    setInductionTemplates(state, payload) {
        state.inductionTemplates = payload;
    },
    addInductionTemplate(state, item) {
        state.inductionTemplates.push(item);
    },
    updateInductionTemplate(state, payload) {
        state.inductionTemplates.splice(payload.index, 1, payload.item);
    },
    removeInductionTemplate(state, index) {
        state.inductionTemplates.splice(index, 1);
    },
    /* Farms */
    setFarms(state, payload) {
        state.farms = payload;
    },
    addFarm(state, payload) {
        payload['index'] = state.farms.farmList.length;
        state.farms.farmList.push(payload);
        state.farms.paddocks.push([]);
        state.farms.fuelTanks.push([]);
        state.farms.silos.push([]);
    },
    addFarmPaddock(state, payload) {
        state.farms.paddocks[payload.farm].push(payload.item);
    },
    addFarmFuelTank(state, payload) {
        state.farms.fuelTanks[payload.farm].push(payload.item);
    },
    addFarmSilo(state, payload) {
        state.farms.silos[payload.farm].push(payload.item);
    },
    updateFarm(state, payload) {
        state.farms.farmList.splice(payload.index, 1, payload);
    },
    updateFarmPaddock(state, payload) {
        state.farms.paddocks[payload.farm].splice(payload.index, 1, payload.item);
    },
    updateFarmFuelTank(state, payload) {
        state.farms.fuelTanks[payload.farm].splice(payload.index, 1, payload.item);
    },
    updateFarmSilo(state, payload) {
        state.farms.silos[payload.farm].splice(payload.index, 1, payload.item);
    },
    removeFarm(state, payload) {
        state.farms.paddocks.splice(payload, 1);
        state.farms.fuelTanks.splice(payload, 1);
        state.farms.silos.splice(payload, 1);
        state.farms.farmList.splice(payload, 1);
    },
    removeFarmPaddock(state, payload) {
        state.farms.paddocks[payload.farm].splice(payload.index, 1);
    },
    removeFarmFuelTank(state, payload) {
        state.farms.fuelTanks[payload.farm].splice(payload.index, 1);
    },
    removeFarmSilo(state, payload) {
        state.farms.silos[payload.farm].splice(payload.index, 1);
    },
    setPaddockMaps(state, payload) {
        state.paddockMaps = payload;
    },
    addPaddockMap(state, item) {
        state.paddockMaps.push(item);
    },
    updatePaddockMap(state, payload) {
        state.paddockMaps.splice(payload.index, 1, payload.item);
    },
    removePaddockMap(state, index) {
        state.paddockMaps.splice(index, 1);
    },
    setChemicalDepots(state, payload) {
        state.chemicalDepots = payload;
    },
    addChemicalDepot(state, item) {
        state.chemicalDepots.push(item);
    },
    updateChemicalDepot(state, payload) {
        state.chemicalDepots.splice(payload.index, 1, payload.item);
    },
    removeChemicalDepot(state, index) {
        state.chemicalDepots.splice(index, 1);
    },
    setWaterStorages(state, payload) {
        state.waterStorages = payload;
    },
    addWaterStorage(state, item) {
        state.waterStorages.push(item);
    },
    updateWaterStorage(state, payload) {
        state.waterStorages.splice(payload.index, 1, payload.item);
    },
    removeWaterStorage(state, index) {
        state.waterStorages.splice(index, 1);
    },
    /* Rain */
    setRainGauges(state, payload) {
        state.rainGauges = payload;
    },
    addRainGauge(state, item) {
        state.rainGauges.push(item);
    },
    updateRainGauge(state, payload) {
        state.rainGauges.splice(payload.index, 1, payload.item);
    },
    removeRainGauge(state, index) {
        state.rainGauges.splice(index, 1);
    },
    setRainRecords(state, payload) {
        state.rainRecords = payload;
    },
    addRainRecord(state, item) {
        state.rainRecords.push(item);
    },
    updateRainRecord(state, payload) {
        state.rainRecords.splice(payload.index, 1, payload.item);
    },
    removeRainRecord(state, index) {
        state.rainRecords.splice(index, 1);
    },
    setCultivars(state, payload) {
        state.cultivars = payload;
    },
    addCultivar(state, item) {
        state.cultivars.push(item);
    },
    updateCultivar(state, payload) {
        state.cultivars.splice(payload.index, 1, payload.item);
    },
    removeCultivar(state, index) {
        state.cultivars.splice(index, 1);
    },
    setLivestock(state, payload) {
        state.livestock = payload;
    },
    addLivestock(state, item) {
        state.livestock.push(item);
    },
    updateLivestock(state, payload) {
        state.livestock.splice(payload.index, 1, payload.item);
    },
    removeLivestock(state, index) {
        state.livestock.splice(index, 1);
    },
    setVetProducts(state, payload) {
        state.vetProducts = payload;
    },
    addVetProduct(state, item) {
        state.vetProducts.push(item);
    },
    updateVetProduct(state, payload) {
        state.vetProducts.splice(payload.index, 1, payload.item);
    },
    removeVetProduct(state, index) {
        state.vetProducts.splice(index, 1);
    },
    setEquipment(state, payload) {
        state.equipment = payload;
    },
    addEquipment(state, item) {
        state.equipment.push(item);
    },
    updateEquipment(state, payload) {
        var newObj = payload.item;
        newObj.files = state.equipment[payload.index].files;
        state.equipment.splice(payload.index, 1, newObj);
    },
    removeEquipment(state, index) {
        state.equipment.splice(index, 1);
    },
    setChemicals(state, payload) {
        state.chemicals = payload;
    },
    addChemical(state, item) {
        state.chemicals.push(item);
    },
    updateChemical(state, payload) {
        var newObj = payload.item;
        newObj.files = state.chemicals[payload.index].files;
        state.chemicals.splice(payload.index, 1, newObj);
    },
    removeChemical(state, index) {
        state.chemicals.splice(index, 1);
    },
    setFertilisers(state, payload) {
        state.fertilisers = payload;
    },
    addFertiliser(state, item) {
        state.fertilisers.push(item);
    },
    updateFertiliser(state, payload) {
        state.fertilisers.splice(payload.index, 1, payload.item);
    },
    removeFertiliser(state, index) {
        state.fertilisers.splice(index, 1);
    },
    /** USERS */
    setUsers(state, payload) {
        state.users = payload;
    },
    addUser(state, item) {
        state.users.push(item);
    },
    updateUser(state, payload) {
        state.users.userDetails.splice(payload.index, 1, payload.item);
    },
    /** CONTRACTORS */
    setContractors(state, payload) {
        state.contractors = payload;
    },
    addContractor(state, item) {
        state.contractors.push(item);
    },
    updateContractor(state, payload) {
        state.contractors.splice(payload.index, 1, payload.item);
    },
    removeContractor(state, index) {
        state.contractors.splice(index, 1);
    },
    /** NOZZLES */
    setNozzles(state, payload) {
        state.nozzles = payload;
    },
    addNozzle(state, item) {
        state.nozzles.push(item);
    },
    updateNozzle(state, payload) {
        state.nozzles.splice(payload.index, 1, payload.item);
    },
    removeNozzle(state, index) {
        state.nozzles.splice(index, 1);
    },
    setWaterLicences(state, payload) {
        state.waterLicences = payload;
    },
    addWaterLicence(state, item) {
        state.waterLicences.push(item);
    },
    updateWaterLicence(state, payload) {
        state.waterLicences.splice(payload.index, 1, payload.item);
    },
    removeWaterLicence(state, index) {
        state.waterLicences.splice(index, 1);
    },
    updateNewKey(state, payload) {
        state.newKey = payload;
    },
    updateUserAndEmail(state, payload) {
        state.users.userDetails.splice(payload.index, 1, payload.item);
        state.userDetails.firstName = payload.item.firstName;
        state.userDetails.lastName = payload.item.lastName;
        state.userDetails.email = payload.item.email;
    },
    /** Cost Codes */
    setCostCodes(state, payload) {
        state.costCodes = payload;
    },
    addCostCode(state, item) {
        state.costCodes.push(item);
    },
    updateCostCode(state, payload) {
        state.costCodes.splice(payload.index, 1, payload.item);
    },
    deleteCostCode(state, index) {
        state.costCodes.splice(index, 1);
    },
    /** Calendar */
    setCalendarItems(state, payload) {
        state.calendarItems = payload;
    },
    addCalendarItem(state, item) {
        state.calendarItems.push(item);
    },
    updateCalendarItem(state, payload) {
        state.calendarItems.splice(payload.index, 1, payload.item);
    },
    deleteCalendarItem(state, index) {
        state.calendarItems.splice(index, 1);
    }
};
